import React from 'react';
import styles from './styles.module.scss';
const Build = () => {
  return (
    <div className={styles.buildContainer}>
      Hello
    </div>
  );
};

export default Build;
